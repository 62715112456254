<template>
  <FadeInOnScroll>
    <div class="mt-10 mb-10 mx-6">
      <TitlePart title="#shihoblog" />
    </div>
  </FadeInOnScroll>
  <AllBlogs :id="props.id" />
</template>

<script setup lang="ts">
import { AllBlogs } from "@/views/components/blogs";
import { FadeInOnScroll, TitlePart } from "@/views/components/common";
import { defineProps, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    id: number;
  }>(),
  {
    id: 1,
  }
);
</script>
