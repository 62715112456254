<template>
  <div class="mt-20">
    <div class="flex justify-center items-center text-[20px] font-bold">
      <RouterLink
        as="span"
        class="w-12 h-12 mx-1.5 my-1.5 flex justify-center rounded-full bg-blue-200 items-center text-white cursor-pointer"
        v-if="currentPage > 2"
        :to="`/blogs/${currentPage > PER_PAGE ? currentPage - PER_PAGE : 1}`"
      >
        {{ currentPage > PER_PAGE ? -PER_PAGE : 1 - currentPage }}
      </RouterLink>
      <RouterLink
        class="w-12 h-12 mx-1.5 my-1.5 flex justify-center rounded-full bg-blue-200 items-center text-white cursor-pointer"
        v-if="currentPage > 1"
        :to="`/blogs/${currentPage - 1}`"
      >
        ＜
      </RouterLink>
      <span
        class="w-12 h-12 mx-1.5 my-1.5 flex justify-center items-center rounded-full border-2 border-blue-200 text-blue-200"
      >
        {{ currentPage }}
      </span>
      <RouterLink
        class="w-12 h-12 mx-1.5 my-1.5 flex justify-center rounded-full bg-blue-200 items-center text-white cursor-pointer"
        v-if="currentPage < totalPage"
        :to="`/blogs/${currentPage + 1}`"
      >
        ＞
      </RouterLink>
      <RouterLink
        class="w-12 h-12 mx-1.5 my-1.5 flex justify-center rounded-full bg-blue-200 items-center text-white cursor-pointer"
        v-if="currentPage + 1 < totalPage"
        :to="`/blogs/${
          currentPage + PER_PAGE < totalPage
            ? currentPage + PER_PAGE
            : totalPage
        }`"
      >
        +{{
          currentPage + PER_PAGE < totalPage
            ? PER_PAGE
            : totalPage - currentPage
        }}
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useBlogsStore } from "@/stores/blogsStore";

const blogsStore = useBlogsStore();

// FIXME: 定数の定義
const PER_PAGE = 20;

const currentPage = computed(() => blogsStore.getCurrentPage);
const totalPage = computed(() => blogsStore.getTotalPage);
</script>
