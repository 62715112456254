<template>
  <div class="mt-10 mx-6">
    <WaitingForLoading v-if="isLoading" />
    <div v-else>
      <p class="text-2xl font-bold">{{ blog.title }}</p>
      <p class="mt-2 text-[12px] text-gray-400 flex justify-end">
        {{ blog.publishedAt }}
      </p>
      <p class="mt-6 whitespace-break-spaces" v-html="formattedContent"></p>
      <div class="mt-16 flex justify-end">
        <a :href="blog.blogUrl" class="text-blue-500 underline" target="_blank"
          >公式ブログ ＞</a
        >
      </div>
    </div>
    <div class="mt-20 flex items-center justify-center">
      <RouterLink
        :to="`/blogs/${blogsStore.getCurrentPage}`"
        class="bg-white font-bold py-3 px-6 rounded-full border border-gray-500 hover:opacity-80"
      >
        一覧へ戻る
      </RouterLink>
    </div>
  </div>
  <FavoriteFloatingActionButton
    :show="isLoggedIn && !isLoading"
    :isFavorite="blog.isFavorite"
    @click="updateFavorite()"
  />
</template>

<script setup lang="ts">
import { computed, defineProps, onMounted, ref, withDefaults } from "vue";
import {
  WaitingForLoading,
  FavoriteFloatingActionButton,
} from "@/views/components/common";
import { useBlogShowStore } from "@/stores/blogShowStore";
import { useFavoriteBlogsStore } from "@/stores/favoriteBlogsStore";
import { useUserSessionsStore } from "@/stores/userSessionsStore";
import { useBlogsStore } from "@/stores/blogsStore";

const props = withDefaults(
  defineProps<{
    id: number;
  }>(),
  {
    id: 0,
  }
);

const blogShowStore = useBlogShowStore();
const blogsStore = useBlogsStore();
const favoriteBlogsStore = useFavoriteBlogsStore();
const userSessionsStore = useUserSessionsStore();

const isLoggedIn = ref(false);

onMounted(async () => {
  isLoggedIn.value = userSessionsStore.getIsLoggedIn;
  await blogShowStore.requestFetchBlog(props.id, isLoggedIn.value);
});

// テキスト中のURLをリンクに変換する
const formatContent = (content: string): string => {
  if (!content) return "";

  // URLの正規表現
  const urlRegex = /((https?:\/\/[^\s]+))/g;
  content = content.replace(
    urlRegex,
    '<a href="$1" target="_blank" class="text-blue-500 underline break-all">$1</a>'
  );

  // 画像の置換
  // ex. [img0] -> <img src="imageUrl" alt="Image 0" class="w-full" />
  const imgRegex = /\[img(\d+)\]/g;
  let imgIndex = 0;
  content = content.replace(imgRegex, (_, imgNumber) => {
    const imageUrl = blog.value.imageUrls[imgIndex];
    imgIndex++;
    if (imageUrl) {
      return `<img src="${imageUrl}" alt="Image ${imgNumber}" class="w-full" />`;
    }
    return `[img${imgNumber}]`; // URLがない場合はそのままにする
  });

  return content;
};

const blog = computed(() => blogShowStore.getBlog);
const isLoading = computed(() => blogShowStore.getIsLoading);

const formattedContent = computed(() =>
  formatContent(blogShowStore.getBlog.content)
);

const updateFavorite = async () => {
  await favoriteBlogsStore.requestPutFavoriteBlogInShow(props.id);
};
</script>
