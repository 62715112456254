<template>
  <div class="mb-4 py-10 text-center text-white bg-blue-400 px-5">
    <p class="font-bold text-3xl">
      加藤史帆さんへ<br />
      ファンからのメッセージ
    </p>
    <p class="mt-10 text-xs leading-5">
      先日、
      <RouterLink
        to="/graduation/messages/form"
        class="underline cursor-pointer"
        >「加藤史帆さん卒業記念企画」
      </RouterLink>
      の応援広告に<br />
      掲載させていただくメッセージを、<br />
      本サイトより募集させていただきました。<br />
      最終的に、180名のファンの方々から<br />
      素敵なメッセージをいただきました！💌<br />
      ご参加いただいた皆さま、誠にありがとうございました！<br />
      <br />
      本ページでは、サイト掲載の許可をいただいた方々の<br />
      メッセージのみを掲載しております。<br />
      ぜひご覧ください！
    </p>
  </div>

  <ChangeFontToCaveat class="mt-8 text-center font-bold text-blue-500 text-3xl" :text="'Thanks for becoming idol.'" />

  <div class="mt-8 mx-4 flex flex-col gap-6">
    <FadeInOnScroll
      ref="scrollAreaRef"
      v-for="(gm, index) in graduationMessages"
      :key="index"
      class="mx-16 py-6 px-4 rounded-md text-center"
      :class="{
        'bg-cyan-50': index % 4 === 0,
        'bg-green-50': index % 4 === 1,
        'bg-yellow-50': index % 4 === 2,
        'bg-pink-50': index % 4 === 3,

        'ml-0': index % 2 === 0,
        'mr-0': index % 2 === 1,
      }"
    >
      <div class="text-sm">{{ gm.message }}</div>
    </FadeInOnScroll>
    <div
      v-if="hasNextPage"
      ref="observerRef"
      class="text-center my-10 text-gray-300"
    >
      Loading...
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useGraduationMessagesStore } from "@/stores/graduationMessagesStore";
import {ChangeFontToCaveat, FadeInOnScroll} from "@/views/components/common";

const graduationMessagesStore = useGraduationMessagesStore();

const scrollAreaRef = ref<HTMLDivElement | null>(null);
const observerRef = ref<HTMLDivElement | null>(null);
const isFetching = ref(false);

const graduationMessages = computed(
  () => graduationMessagesStore.getGraduationMessages
);
const hasNextPage = computed(() => graduationMessagesStore.hasNextPage());

const fetchNextPage = async () => {
  if (isFetching.value) return;

  isFetching.value = true;
  try {
    await graduationMessagesStore.requestFetchList(
      graduationMessagesStore.getListCurrentPage + 1
    );
  } finally {
    isFetching.value = false;
  }
};

onMounted(() => {
  // 初回のデータ取得
  graduationMessagesStore.requestFetchList(1);

  // IntersectionObserverの設定
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasNextPage.value) {
          fetchNextPage();
        }
      });
    },
    {
      root: null,
      threshold: 0, // 0.0 ~ 1.0 : 発火させる表示領域の割合
    }
  );

  if (observerRef.value) {
    observer.observe(observerRef.value);
  }

  onUnmounted(() => {
    observer.disconnect();
  });
});
</script>
