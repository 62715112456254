<template>
  <div class="mx-6">
    <div v-if="isLoading">
      <WaitingForLoading />
    </div>

    <div v-else-if="blogs.length !== 0">
      <div
        v-if="currentViewType === 'favorite'"
        class="my-6 bg-blue-100 rounded-md py-4 text-center text-site-color"
      >
        <p>お気に入りのブログのみを表示中</p>
        <span
          @click="changeViewTypeToAll()"
          class="mt-3 text-xs cursor-pointer underline"
        >
          すべてのブログを表示する
        </span>
      </div>

      <div class="flex justify-between items-center">
        <span>
          <RouterLink v-if="currentPage > 1" :to="`/blogs/${currentPage - 1}`" class="text-2xl">＜</RouterLink>
        </span>
        <div>PAGE：{{ currentPage }} / {{ totalPage }}</div>
        <span>
          <RouterLink v-if="currentPage < totalPage" :to="`/blogs/${currentPage + 1}`" class="text-2xl">＞</RouterLink>
        </span>
      </div>

      <div class="mt-10">
        <div v-for="(blog, index) in blogs" :key="index" class="mb-6">
          <BlogCard
            :id="blog.id"
            :title="blog.title"
            :publishedAt="blog.publishedAt"
            :imageUrls="blog.imageUrls"
            :isFavorite="blog.isFavorite"
          />
        </div>
      </div>

      <FadeInOnScroll>
        <PaginationButton />
      </FadeInOnScroll>
    </div>

    <FadeInOnScroll v-else class="my-24 text-center text-site-color text-sm">
      <p>ブログはありません</p>
    </FadeInOnScroll>

    <div
      v-if="!isLoading"
      class="mt-16 text-center text-sm text-site-color underline"
    >
      <span
        v-if="currentViewType === 'all'"
        class="cursor-pointer"
        @click="isLoggedIn ? changeViewTypeToFavorite() : openSignupModal()"
      >
        お気に入りだけを表示する
      </span>
      <span v-else class="cursor-pointer" @click="changeViewTypeToAll()">
        すべてのブログを表示する
      </span>
    </div>
  </div>

  <SignupModal
    :isOpen="isSignupModalOpen"
    @update:isOpen="handleSignupModalUpdate"
  />
</template>

<script setup lang="ts">
import {
  computed,
  ref,
  withDefaults,
  defineProps,
  watch,
  onMounted,
} from "vue";
import { FadeInOnScroll, WaitingForLoading } from "@/views/components/common";
import { useBlogsStore } from "@/stores/blogsStore";
import BlogCard from "@/views/components/blogs/index/BlogCard.vue";
import SignupModal from "@/views/components/blogs/index/SignupModal.vue";
import PaginationButton from "@/views/components/blogs/index/PaginationButton.vue";
import { useUserSessionsStore } from "@/stores/userSessionsStore";
import { useRoute } from "vue-router";
import router from "@/router";

const blogsStore = useBlogsStore();
const userSessionsStore = useUserSessionsStore();

const props = withDefaults(
  defineProps<{
    id: number;
  }>(),
  {
    id: 1,
  }
);

onMounted(() => {
  blogsStore.requestGetBlogs(props.id);
});

const currentViewType = computed(() => blogsStore.getCurrentViewType);

const blogs = computed(() => blogsStore.getBlogs);
const currentPage = computed(() => blogsStore.getCurrentPage);
const totalPage = computed(() => blogsStore.getTotalPage);
const isLoading = computed(() => blogsStore.getIsLoading);
const isLoggedIn = computed(() => userSessionsStore.getIsLoggedIn);

// パスパラメータが変わったら再取得
const route = useRoute();
watch([() => route.params.id, () => currentViewType.value], () => {
  window.scrollTo(0, 0);
  blogsStore.requestGetBlogs(props.id, currentViewType.value === "favorite");
});

const changeViewTypeToAll = async () => {
  if (currentViewType.value === "all") return;

  window.scrollTo(0, 0);
  blogsStore.setCurrentViewType("all");
  await router.push("/blogs/1");
};

const changeViewTypeToFavorite = async () => {
  if (currentViewType.value === "favorite") return;

  window.scrollTo(0, 0);
  blogsStore.setCurrentViewType("favorite");
  await router.push("/blogs/1");
};

const isSignupModalOpen = ref(false);
const openSignupModal = () => {
  isSignupModalOpen.value = true;
};

const handleSignupModalUpdate = (newVal: boolean) => {
  isSignupModalOpen.value = newVal;
};
</script>
